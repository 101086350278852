import { Route } from "vue-router";
import { DashboardRouteNames } from "./../../shared/enums/RouteNames/dashboardRouteNames.enum";
export const DashboardRouteList = [
  {
    path: "home",
    name: DashboardRouteNames.Home,
    component: () => import(/* webpackChunkName: "DashboardPage" */ "@/pages/dashboard/DashboardPage.vue"),
    meta: { isEnabledForMemberOrganizations: true },
  },
  {
    path: "home/pendingCourses",
    name: DashboardRouteNames.PendingCourses,
    component: () => import(/* webpackChunkName: "PendingCoursesPage" */ "@/pages/dashboard/PendingCoursesPage.vue"),
  },
  {
    path: "home/plansforApproval",
    name: DashboardRouteNames.PlansForApproval,
    component: () =>
      import(/* webpackChunkName: "PlansForApprovalPage" */ "@/pages/dashboard/PlansForApprovalPage.vue"),
  },
  {
    path: "home/pendingWorkingHours",
    name: DashboardRouteNames.PendingWorkingHours,
    component: () =>
      import(/* webpackChunkName: "PlansForApprovalPage" */ "@/pages/dashboard/PendingWorkingHoursPage.vue"),
  },
  {
    path: "home/pendingOrders",
    name: DashboardRouteNames.PendingOrders,
    component: () => import(/* webpackChunkName: "PendingOrdersPage" */ "@/pages/dashboard/PendingOrdersPage.vue"),
  },
  {
    path: "home/newApplications/:groupBy?",
    name: DashboardRouteNames.NewApplications,
    props: (route: Route) => ({
      groupBy: route.params.groupBy,
    }),
    component: () => import(/* webpackChunkName: "NewApplicationsPage" */ "@/pages/dashboard/NewApplicationsPage.vue"),
    meta: { isEnabledForMemberOrganizations: true },
  },
  {
    path: "home/preApproved/",
    name: DashboardRouteNames.PreApproved,
    component: () =>
      import(/* webpackChunkName: "NewApplicationsPage" */ "@/pages/dashboard/PreApprovedParticipantsPage.vue"),
    meta: { isEnabledForMemberOrganizations: true },
  },
  {
    path: "home/preApprovedAofCourses/",
    name: DashboardRouteNames.PreApprovedAofCourses,
    /* TODO: find a way to show only users preapproved in AOF courses using same component */
    component: () =>
      import(
        /* webpackChunkName: "NewApplicationsPage" */ "@/pages/dashboard/PreApprovedAofCoursesParticipantsPage.vue"
      ),
    meta: { isEnabledForMemberOrganizations: true },
  },
  {
    path: "home/unreadCourseMessages",
    name: DashboardRouteNames.UnreadCourseMessages,
    component: () =>
      import(/* webpackChunkName: "UnreadCourseMessagesPage" */ "@/pages/dashboard/UnreadCourseMessagesPage.vue"),
  },
  {
    path: "home/gjennomforingsplaner/edit/studieplan/:id",
    name: DashboardRouteNames.PreRequisitionsExecutionPlan,
    component: () =>
      import(
        /* webpackChunkName: "EditImplementationPlanPage" */ "@/pages/gjennomforingsplaner/EditImplementationPlanPage.vue"
      ),
  },
  {
    path: "home/gjennomforingsplaner/ny/:id",
    name: DashboardRouteNames.NewExecutionPlan,
    component: () =>
      import(
        /* webpackChunkName: "NewImplementationPlanPage" */ "@/pages/gjennomforingsplaner/NewImplementationPlanPage.vue"
      ),
  },
  {
    path: "home/facilitationGrants/:filter?",
    name: DashboardRouteNames.FacilitationGrants,
    props: (route: Route) => ({
      filter: route.params.filter,
    }),
    component: () =>
      import(
        /* webpackChunkName: "FacilitationGrantsDashboardPage" */ "@/pages/dashboard/FacilitationGrantsDashboardPage.vue"
      ),
  },
  {
    path: "home/coursesWithLowAttendance",
    name: DashboardRouteNames.CoursesWithLowAttendance,
    component: () =>
      import(/* webpackChunkName: "NewImplementationPlanPage" */ "@/pages/dashboard/CourseWithLowAttendancePage.vue"),
  },
  {
    path: "home/newDocuments",
    name: DashboardRouteNames.NewDocuments,
    component: () => import(/* webpackChunkName: "NewDocumentsPage" */ "@/pages/dashboard/NewDocumentsPage.vue"),
  },
  {
    path: "home/changedParticipants",
    name: DashboardRouteNames.ChangedParticipants,
    props: (route: Route) => ({
      groupBy: route.params.groupBy,
    }),
    component: () =>
      import(/* webpackChunkName: "ChangedCourseParticipants" */ "@/pages/dashboard/ChangedCourseParticipants.vue"),
  },
  {
    path: "home/contracts",
    name: DashboardRouteNames.Contracts,
    component: () => import(/* webpackChunkName: "NonJournaledCourses" */ "@/pages/dashboard/ContractsPage.vue"),
  },
];
